@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap);
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: 9999;
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, 9999 px);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: 320px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: sans-serif;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.7s;
          animation-duration: 0.7s;
}

.Toastify--animate-icon {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: #121212;
  background: var(--toastify-color-dark);
  color: #fff;
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: #fff;
  background: var(--toastify-color-light);
  color: #757575;
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #fff;
  background: var(--toastify-color-light);
  color: #757575;
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: #fff;
  color: var(--toastify-text-color-info);
  background: #3498db;
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: #fff;
  color: var(--toastify-text-color-success);
  background: #07bc0c;
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #fff;
  color: var(--toastify-text-color-warning);
  background: #f1c40f;
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #fff;
  color: var(--toastify-text-color-error);
  background: #e74c3c;
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: #bb86fc;
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: #3498db;
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: #07bc0c;
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: #f1c40f;
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: #e74c3c;
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: rgba(255, 255, 255, 0.7);
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  -webkit-transform-origin: left;
          transform-origin: left;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform-origin: right;
          transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: #e0e0e0;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: #616161;
  border-right-color: var(--toastify-spinner-color);
  -webkit-animation: Toastify__spin 0.65s linear infinite;
          animation: Toastify__spin 0.65s linear infinite;
}

@-webkit-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}

@-webkit-keyframes Toastify__spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Toastify__spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
#near-wallet-selector-modal {
    --backdrop-bg: #26262630;
    --heading-color: #222222;
    --text-color: #676767;
    --sidebar-border-color: #EDEDED;
    --selected-wallet-bg: #4F7CD1;
    --selected-wallet-bg-hover: transparent;
    --wallet-option-border-color: #A7A7A730;
    --wallet-option-bg-hover: #EDEDED;
    --content-bg: #FFFFFF;
    --change-path-bg: #EDEDED;
    --home-button-bg: #EDEDED;
    --confirm-button-bg: #5F8AFA;
    --confirm-button-bg-hover: #5AA6FF;
    --error: #DB5555;
    --close-button-bg-color: #EDEDED;
    --close-button-fill-icon-color: #9F9F9F;
    --spinner-color: #676767;
    --bottom-section: #ececec;
    --mobile-text: #3e3e3e;
    --connected-green: #47E586;
}

#near-wallet-selector-modal .dark-theme {
    --backdrop-bg: #26262630;
    --heading-color: #FFFFFF;
    --text-color: #C1C1C1;
    --sidebar-border-color: #313030;
    --selected-wallet-bg: #4F7CD1;
    --selected-wallet-bg-hover: #262626CC;
    --wallet-option-border-color: #A7A7A730;
    --wallet-option-bg-hover: #313030;
    --content-bg: #232323;
    --change-path-bg: #161616;
    --home-button-bg: #313030;
    --confirm-button-bg: #5F8AFA;
    --confirm-button-bg-hover: #5AA6FF;
    --error: #DB5555;
    --close-button-bg-color: #313030;
    --close-button-fill-icon-color: #C1C1C1;
    --spinner-color: #FFFFFF;
    --bottom-section: #131313;
    --mobile-text: #c1c1c1;
    --connected-green: #47E586;
}

@media (prefers-color-scheme: dark) {
    #near-wallet-selector-modal {
        --backdrop-bg: #26262630;
        --heading-color: #FFFFFF;
        --text-color: #C1C1C1;
        --sidebar-border-color: #313030;
        --selected-wallet-bg: #4F7CD1;
        --selected-wallet-bg-hover: #262626CC;
        --wallet-option-border-color: #A7A7A730;
        --wallet-option-bg-hover: #313030;
        --content-bg: #232323;
        --change-path-bg: #161616;
        --home-button-bg: #313030;
        --confirm-button-bg: #5F8AFA;
        --confirm-button-bg-hover: #5AA6FF;
        --error: #DB5555;
        --close-button-bg-color: #313030;
        --close-button-fill-icon-color: #C1C1C1;
        --spinner-color: #FFFFFF;
        --bottom-section: #131313;
        --mobile-text: #c1c1c1;
        --connected-green: #47E586;
    }
}

/**
 * Modal Wrapper
 */

.nws-modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    /*transition: visibility 0s linear 0.25s, opacity 0.25s 0s;*/
    color: var(--text-color);
    color: var(--wallet-selector-text-color, var(--text-color));
    font-family: Manrope, sans-serif;
    font-weight: 500;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nws-modal-wrapper .nws-modal-overlay {
    background: var(--backdrop-bg);
    background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
    height: 100%;
    width: 100%;
    position: absolute;
}

/**
 * Modal
 */

.nws-modal-wrapper .nws-modal {
    background: var(--content-bg);
    background: var(--wallet-selector-content-bg, var(--content-bg));
    width: 100%;
    max-width: 812px;
    height: 555px;
    border-radius: 16px;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, -webkit-transform 0.25s;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s, -webkit-transform 0.25s;
    background-color: var(--content-bg);
    background-color: var(--wallet-selector-content-bg, var(--content-bg));
    overflow-y: auto;
    font-size: 16px;
    line-height: 1.6;
    overflow: hidden;
    display: inline-flex;
}

.nws-modal-wrapper .nws-modal * {
  box-sizing: border-box;
}

.nws-modal-wrapper .nws-modal button {
  padding: 0.5em 1em;
  cursor: pointer;
  border: none;
}

/**
* Modal Left Side
*/

.nws-modal-wrapper .nws-modal .modal-left {
    width: 35%;
    border-right: 1px solid var(--sidebar-border-color);
    border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
    padding: 32px 24px;
    height: 100%;
    overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.nws-modal-wrapper .nws-modal .modal-left::-webkit-scrollbar {
    width: 10px;
}

/**
 * Modal Wallet Options Section/Wrapper
 */

.nws-modal-wrapper .nws-modal .wallet-options-wrapper {
    margin-bottom: 20px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
    margin-top: 0;
    margin-bottom: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 10px;
    gap: 10px;
}

.single-wallet {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    padding: 12px;
    cursor: pointer;
}

.single-wallet .icon {
    height: 48px;
    width: auto;
}

.single-wallet .icon img {
    width: 100%;
    height: auto;
    max-width: 48px;
}

.single-wallet .content {
    width: 50%;
}

.single-wallet .content .name {
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.single-wallet .content .description {
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
}

.single-wallet .button-get {
    margin-left: auto;
    margin-right: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar {
    border-radius: 8px;
    justify-content: flex-start;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    cursor: pointer;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover {
    background-color: var(--wallet-option-bg-hover);
    background-color: var(--wallet-selector-wallet-option-bg-hover, var(--wallet-option-bg-hover));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet {
    padding: 12px;
    background-color: var(--selected-wallet-bg);
    background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet .content .title {
    color: #FFFFFF;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet {
    opacity: 0.4;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .warning-triangle {
    display: flex;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .icon {
    width: 40px;
    height: 40px;
    background-color: #FFF;
    padding: 5px;
    border-radius: 5px;
    position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon::before {
  border: 3px solid var(--content-bg);
  border: 3px solid var(--wallet-selector-content-bg, var(--content-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet.connected-wallet .icon::before {
  border: 3px solid var(--selected-wallet-bg);
  border: 3px solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content {
    height: 32px;
    width: auto;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    /* identical to box height, or 171% */
    align-items: center;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
    margin-left: 10px;
  }

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .description {
    display: none;
}

/**
* Modal Right Side
*/

.nws-modal-wrapper .nws-modal .modal-right {
    width: 65%;
    padding: 32px;
    overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
    width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper,
.nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper,
.nws-modal-wrapper .nws-modal .connecting-wrapper,
.nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper,
.nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  margin-top: 91px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper-err {
    margin-top: 45px;
    padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
    padding: 0;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what {
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    grid-gap: 32px;
    gap: 32px;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what + .wallet-what {
    margin-top: 50px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
    width: 15%;
    padding-top: 9px;
    padding-bottom: 9px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side img {
    width: 100%;
    height: auto;
    max-height: 65px;
    border-radius: 6px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side {
    width: 100%;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3 {
    margin: 0 auto 8px 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--heading-color);
    color: var(--wallet-selector-text-color, var(--heading-color));
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side p {
    margin: 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    word-break: break-word;
    width: 80%;
}

/**
 * Modal Header
 */

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
    text-align: center;
    margin: 4px auto;
    font-size: 18px;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-body.get-wallet-body {
    padding-left: 32px;
    padding-right: 0;
    grid-row-gap: 16px;
    row-gap: 16px;
    margin-top: 64px;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
    background-color: var(--selected-wallet-bg);
    background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
    font: inherit;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border-color: var(--selected-wallet-bg);
    border-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
    display: block;
    margin: 25px auto 12px auto;
    border: 0.1em solid var(--selected-wallet-bg);
    border: 0.1em solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
    margin: 4px 0;
    background-color: var(--home-button-bg);
    background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
    border-radius: 4px;
    padding: 8px 24px;
    border-width: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    font-family: inherit;
    color: #6494EE;
}

.nws-modal-wrapper .nws-modal .nws-modal-header {
    width: 100%;
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    justify-content: space-between;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
    border: 0;
    cursor: pointer;
    height: 32px;
    padding: 4px;
    background-color: var(--close-button-bg-color);
    background-color: var(--wallet-selector-close-button-bg-color, var(--close-button-bg-color));
    border-radius: 50px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
    fill: var(--close-button-fill-icon-color);
    fill: var(--wallet-selector-close-button-fill-icon-color, var(--close-button-fill-icon-color));
}

.nws-modal-wrapper .nws-modal .back-button {
    background: transparent;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    /* vertical-align: middle; */
    border: none;
    padding: 4px
}

.nws-modal-wrapper .nws-modal .nws-modal-header-wrapper {
    display: flex;
    align-items: center;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button:active {
    background: transparent;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
    pointer-events: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.nws-modal-wrapper .nws-modal .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/**
 * Modal Switch Network Message Section/Wrapper
 */


.nws-modal-wrapper .nws-modal .switch-network-message-wrapper .content .network-id {
    color: var(--selected-wallet-bg);
    color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

/**
 * Modal Ledger Derivation Path Section/Wrapper
 */

.nws-modal-wrapper .nws-modal .derivation-path-wrapper {
    padding: 0 26px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
    display: flex;
    justify-content: center;
    margin-top: 53px;
    margin-bottom: 40px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description {
    font-size: 14px;
    text-align: center;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description .specify-path {
    color: var(--selected-wallet-bg);
    color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
    cursor: pointer;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 16px;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 91px;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .display-path {
    background: var(--home-button-bg);
    color: #606060;
    padding: 16px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 700;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path {
    background: var(--change-path-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    width: 63px;
    padding: 0 5px;
    border-radius: 8px;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .path-value {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .path-description {
    font-size: 14px;
    margin-top: 24px;
    text-align: center;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link {
    font-size: 14px;
    text-align: center;
    color: var(--selected-wallet-bg);
    cursor: pointer;
}


.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper {
    display: flex;
    flex-direction: column;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper button {
    padding: 0;
    width: 23px;
    background-color: var(--home-button-bg);
    border: none;
}

.nws-modal-wrapper .nws-modal .no-accounts-found-wrapper {
    margin-top: 50px;
    font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account {
    margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account p {
    text-align: center;
    font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 90px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper input {
    text-align: center;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
    border: 1px solid var(--confirm-button-bg-hover);
    padding: 6px 8px;
    border-radius: 50px;
}

/**
 * Modal Wallet ChooseLedgerAccountForm/Wrapper
 */

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper p {
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper {
    display: flex;
    justify-content: center;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper button {
    color: var(--selected-wallet-bg);
    font-weight: 600;
    background-color: transparent;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid var(--selected-wallet-bg);
    width: 78px;
    padding: 6px 8px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .form {
    margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 10px;
    color: var(--text-color);
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account {
    border-bottom: 1px solid var(--sidebar-border-color);
    padding: 16px 0;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account input[type=checkbox] {
    width: 25px;
    -webkit-transform: scale(2);
    transform: scale(1.7);
    padding: 10px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control label {
    color: inherit;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .action-buttons {
    justify-content: flex-end;
}

.nws-modal-wrapper .nws-modal .overview-wrapper p {
    font-size: 14px;
    text-align: center;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .accounts {
    margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .account {
    border-bottom: 1px solid var(--sidebar-border-color);
    padding: 16px 0;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

/*
  Connecting Wrapper Section/Wrapper
*/
.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/**
 * Modal Wallet Not Installed Section/Wrapper
 */

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
    color: #5f8afa;
    cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
    display: flex;
    align-items: center;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
    margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
    width: 40px;
    height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
    width: 100%;
    height: auto;
}

.open {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
}

.nws-modal-wrapper .spinner {
    margin-right: 10px;
    --size: 160px;
    --border: 6px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nws-modal-wrapper .spinner .icon {
    width: calc(var(--size) / 1.2);
    height: calc(var(--size) / 1.2);
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0 10px 20px 0;
}

.nws-modal-wrapper .spinner img {
    width: 100%;
    height: auto;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    .nws-modal-wrapper .nws-modal-wrapper .nws-modal {
        width: 250px;
    }

    .nws-modal-wrapper .nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list input {
        max-width: 140px;
    }

    .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
        flex-direction: column;
    }

    .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control select {
        text-align: center;
    }
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #near-wallet img,
.nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #math-wallet img,
.nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal #ledger img,
.nws-modal-wrapper .nws-modal-wrapper.dark-theme .nws-modal .wallet-not-installed-wrapper .math-wallet img {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .spinner .icon {
    box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.05);
}

@media (prefers-color-scheme: dark) {

    .nws-modal-wrapper .nws-modal #near-wallet img,
    .nws-modal-wrapper .nws-modal #math-wallet img,
    .nws-modal-wrapper .nws-modal #ledger img,
    .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper .math-wallet img {
        -webkit-filter: invert(1);
                filter: invert(1);
    }

    .nws-modal-wrapper .spinner .icon {
        box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.05);
    }
}

@-webkit-keyframes outAnimation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes outAnimation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

/**
* Modal Wallet Connecting Section/Wrapper
*/

.connecting-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}

.connecting-wrapper .content .icon {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 10px;
    padding: 10px
}

.connecting-wrapper .content .icon img {
    width: 100%;
    height: auto;
}

.connecting-wrapper .content .connecting-name {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.connecting-wrapper .content .connecting-details {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.connecting-wrapper .content .connecting-details span {
    color: var(--selected-wallet-bg);
    color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}
.connecting-wrapper .content .connecting-details .spinner {
    width: 25px;
    height: auto;
    -webkit-animation: spinner 2s linear infinite;
            animation: spinner 2s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection button {
    margin: 24px auto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: #6494EE;
    align-items: center;
    padding: 8px 24px;
    grid-gap: 8px;
    gap: 8px;
    width: 86px;
    height: 40px;
    background-color: var(--home-button-bg);
    background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
    border-radius: 4px;
    border: 0;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper {
    vertical-align: middle;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error {
    color: #CE5A6F;
    vertical-align: middle;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper .error svg {
  color: var(--content-bg);
  color: var(--wallet-selector-content-bg,var(--content-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .success {
    color: #4FD98F;
}


.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
    padding: 25px;
}

.connecting-wrapper .content .connecting-details {
    margin-top: 20px;
}

.connecting-wrapper .wallet-connected-success {
  display: flex;
  align-items: center;
}

.connecting-wrapper .wallet-connected-success svg{
  color: var(--content-bg);
}

.connecting-wrapper .wallet-connected-success span {
  font-size: 14px;
  margin-left: 10px;
  color: var(--connected-green);
  color: var(--wallet-selector-connected-green, var(--connected-green));
}


.single-wallet-get {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    padding: 12px;
    max-width: 450px;
    margin: auto;
    margin-top: 20px;
}

.single-wallet-get .icon {
    min-width: 48px;
    max-height: 48px;
    width: 48px;
    height: 48px;
    background-color: #FFF;
    padding: 5px;
    border-radius: 4px;
    position: relative;
}

.single-wallet-get .icon img {
    width: 100%;
    height: auto;
    max-width: 48px;
}

.single-wallet-get .title {
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--heading-color);
    color: var(--wallet-selector-heading-color, var(--heading-color));
}

.single-wallet-get .description {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
}


.single-wallet-get .button-get {
    margin-left: auto;
    margin-right: 0;
}

.connected-flag {
    color: var(--connected-green);
    border: 1px solid var(--connected-green);
    border-radius: 50px;
    padding: 3px 10px;
    display: flex;
    align-items: center;
}

.connected-flag::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--connected-green);
    margin-right: 5px;
}

/************* Responsive and mobile **********/

@media (min-width: 577px) {
    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile {
        display: none;
    }
}

@media (min-width: 769px) {
    .button-spacing {
      margin: 90px
    }
}


@media (max-width: 768px) {

    .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
      margin-top: 45px;
    }

    .button-spacing {
      margin: 45px
    }


    .nws-modal-wrapper .nws-modal .modal-left {
      width: 40%;
      border-right: 1px solid var(--sidebar-border-color);
      border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
      padding: 32px 16px;
      height: 100%;
      max-height: 70vh;
      overflow: auto;
    }

    .nws-modal-wrapper .nws-modal .modal-right {
      width: 60%;
      padding: 32px 16px;
      overflow: auto;
    }

    .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
      padding:  0 0 0 10px;
    }

   .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
      margin-top: 30px;
      margin-bottom: 35px;
    }
}


@media (max-width: 576px) {

    .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper,
    .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper,
    .nws-modal-wrapper .nws-modal .connecting-wrapper,
    .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper,
    .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
        margin-top: 20px;
    }

    .nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
      text-align: center;
    }

    .nws-modal-wrapper .wallet-not-installed-wrapper > p {
        margin: 20px 0px 30px 0px;
        max-width: 500px;
    }

    .nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
      background-color: var(--content-bg);
      background-color: var(--wallet-selector-home-button-bg, var(--content-bg));
    }

    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-hide {
        display: none;
    }

    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile p {
        font-size: 14px;
        margin-bottom: 0;
        text-align: center;
        margin: auto;
    }



    .nws-modal-wrapper .nws-modal {
        width: 100%;
        display: block;
        overflow: auto;
        bottom: 0;
        height: 500px;
        background: var(--bottom-section);
        background: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
        border-radius: 16px 16px 0px 0px;
    }

    .nws-modal-wrapper .nws-modal .modal-left {
        width: 100%;
        background-color: var(--content-bg);
        background-color: var(--wallet-selector-content-bg, var(--content-bg));
        height: auto;
        padding: 32px 12px;
    }

    .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body {
        display: flex;
        overflow: auto;
    }

    .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body .wallet-options-wrapper {
        display: flex;
        margin: auto;
    }

    .nws-modal-wrapper .nws-modal .modal-right {
        width: 100%;
        background-color: var(--bottom-section);
        background-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    }

    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
        text-align: center;
        font-size: 16px;
        margin: 4px auto;
    }

    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .content {
        font-size: 14px;
        text-align: center;
        color: var(--mobile-text);
        margin: 0
    }

    .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body {
        margin-top: 10px;
        padding: 0;
    }

    .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
        margin: 25px auto 12px auto;
    }

    .nws-modal-wrapper .nws-modal .modal-header {
        display: block;
        font-size: 18px;
        text-align: center;
    }

    .nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .nws-modal-wrapper .nws-modal .nws-modal-header h2 {
        font-size: 18px;
        text-align: center;
    }

    .nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
        display: none;
    }

    .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
        display: flex;
        overflow-x: auto;
    }

    .nws-modal-wrapper .nws-modal .info {
        display: none;
        width: 90px;
    }

    .single-wallet {
        display: block;
        width: 85px;
    }

    .single-wallet.sidebar .icon {
        width: 56px;
        height: 56px;
        margin: auto;
    }

    .single-wallet.sidebar .content {
        width: auto;
    }

    .single-wallet .content .title {
        margin-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
        margin-top: 0;
    }

    .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image,
    .nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description > p {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background: var(--backdrop-bg);

    }

    ::-webkit-scrollbar-thumb:horizontal {
        background: var(--close-button-fill-icon-color);
        border-radius: 10px;
    }
}

